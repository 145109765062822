<template>
  <BTableCard
    :searchActive="false"
    :newAddActive="false"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-pallet-alt'"
    :title="'Ürün Satış Raporu'"
    :total="total"
    @handlerAddNew="handlerAddNew"
    @handlerBackTo="handlerBackTo"
  >
    <template v-slot:headerAction>
      <div>
        <b-button
          squared
          variant="warning"
          class="icon btn-icon mr-1"
          v-if="!backTo"
          @click="filtered = !filtered"
          v-b-tooltip.hover="'Filtrele'"
        >
          <i class="fad fa-filter" />
        </b-button>
      </div>
    </template>
    <template v-slot:body>
      <div class="p-1">
        <transition mode="out-in">
          <router-view
            :filtered="filtered"
            @filtered="handlerFiltered($event)"
            @total="total = $event"
            @show="show = $event"
            @backTo="backTo = $event"
          />
        </transition>
      </div>
    </template>
  </BTableCard>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import BTableCard from '@/components/cards/BTableCard.vue';
import { useRouter } from '@/libs/utils';

export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.filtered = ref(true);
    expo.show = ref(false);
    expo.backTo = ref(false);
    expo.total = ref(0);

    expo.handlerFiltered = (event) => {
      expo.filtered.value = event;
    };

    expo.handlerAddNew = () => {};

    expo.handlerBackTo = () => {};
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
